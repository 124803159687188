


/*===========================
    15.QUOTE css 
===========================*/

.quote-area{
	background-attachment: fixed;
	& .quote-overlay{
		background-color: rgba(0, 18, 34,.8);
		padding-top: 105px;
		padding-bottom: 120px;
		& .section-title-2{
			& .title{
				color: $white;
			}
			& p{
				color: $white;
			}
		}
		& .quote-form{
			width: 100%;
			text-align: center;
			& .input-box{
				position: relative;
				& input{
					width: 100%;
					background-color: transparent;
					border: 2px solid rgba(255, 255, 255,.2);
					padding-left: 40px;
					line-height: 80px;
					color: #c1d0de;
					&::placeholder{
						opacity: 1;
						color: #c1d0de;
					}
				}
				& textarea{
					width: 100%;
					height: 200px;
					background-color: transparent;
					border: 2px solid rgba(255, 255, 255,.2);
					padding-top: 30px;
					padding-left: 40px;
					color: #c1d0de;
					resize: none;
					&::placeholder{
						opacity: 1;
						color: #c1d0de;
					}
				}
				& button{
					margin-top: 38px;
					border-radius: 0;
				}
				& svg{
					position: absolute;
					top: 35px;
					right: 40px;

				}
			}
		}
	}
}
